import { testMyRentalsShallowMount } from "@/tests/unit/setup";
import { myRentalsShallowMount } from "@/tests/unit/setup";
import DocumentsPageContent from "@/components/protected/DocumentsPageContent.vue";
import { LocalStorageKeys } from "@/enums/Storage";
import Storage from "@/plugins/storage";

const defaultMocks = {
  $router: { push: jest.fn() },
};

const component = (data = {}, props = {}, mocks = defaultMocks) => {
  return myRentalsShallowMount(DocumentsPageContent, {
    data,
    props,
    mocks,
    router: true,
  });
};

beforeEach(() => {
  jest.restoreAllMocks();
});

const mockedUrl = "url";

describe("DocumentsPageContent", () => {
  testMyRentalsShallowMount(DocumentsPageContent, {
    router: true,
  });

  describe("Methods", () => {
    describe("goToDocumentView", () => {
      it("should call set local storage", () => {
        // given
        spyOn(Storage, "setLocalStorageItem");
        const wrapper = component();
        // when
        component();
        wrapper.vm.goToDocumentView(mockedUrl);
        // then
        expect(Storage.setLocalStorageItem).toHaveBeenCalledWith(
          LocalStorageKeys.IFRAME_URL,
          mockedUrl
        );
      });
      it("should call router push", () => {
        // given
        const wrapper = component();
        spyOn(wrapper.vm.$router, "push");
        // when
        component();
        wrapper.vm.goToDocumentView(mockedUrl);
        // then
        expect(wrapper.vm.$router.push).toHaveBeenCalledTimes(1);
      });
    });
  });
});
