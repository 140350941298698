
import { Component, Vue, Prop } from "vue-property-decorator";
import { HousfyInputSelect } from "housfy-ui-lib";
import IOption from "@/models/IOption";

@Component({
  components: {
    HousfyInputSelect,
  },
})
export default class AccountingFilterYear extends Vue {
  @Prop({ type: Array, required: true })
  accountingYears!: IOption<number>[];

  @Prop({ type: Number })
  accountingYear!: number;

  get defaultOption() {
    if (!this.accountingYear) return 0;
    const index = this.accountingYears.findIndex(
      (item) => item.value === this.accountingYear
    );
    return index > 0 ? index : 0;
  }
}
