
import { Component, Vue, Prop } from "vue-property-decorator";
import { HousfyFlapV2, HousfyButton, HousfyRadioButtons } from "housfy-ui-lib";
import AccountingFilterYear from "@/components/AccountingFilterYear.vue";
import IOption from "@/models/IOption";
import { AccountingSummary } from "@/enums/Accounting";

@Component({
  components: {
    HousfyFlapV2,
    HousfyRadioButtons,
    HousfyButton,
    AccountingFilterYear,
  },
})
export default class AccountingFilter extends Vue {
  selectedYear: IOption<number> | null = null;
  selectedSummary: IOption<number> | null = null;

  @Prop({ type: Number, required: true })
  accountingYear!: number;

  @Prop({ type: Array, required: true })
  accountingYears!: IOption<number>[];

  @Prop({ type: Number, required: true })
  ownerPercentage!: number;

  @Prop({ type: Number, default: AccountingSummary.TOTAL_PROPERTY })
  summaryView!: number;

  selectYear(year: IOption<number>) {
    this.selectedYear = year;
  }
  selectSummary(summary: IOption<number>) {
    this.selectedSummary = summary;
  }

  resetFilter() {
    this.$emit("reset", {
      year: this.accountingYears[0],
      summary: this.summaries[0],
    });
  }

  handleFilter() {
    this.$emit("filter", {
      year: this.selectedYear,
      summary: this.selectedSummary,
    });
  }

  get selectedSummaryOption() {
    const { label, value } = this.summaries[this.summaryView];
    return { label, value };
  }

  get summaries() {
    return [
      {
        label: this.$t("common.viewTheTotalProperty"),
        value: AccountingSummary.TOTAL_PROPERTY,
      },
      {
        label: this.$t("common.seeOnlyMyPart"),
        value: AccountingSummary.OWNER,
      },
    ];
  }
}
