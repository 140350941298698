import {
  testMyRentalsShallowMount,
  myRentalsShallowMount,
} from "@/tests/unit/setup";
import AccountingFilterYear from "@/components/AccountingFilterYear.vue";

const defaultProps = {
  accountingYears: [
    {
      label: "2025",
      value: 2025,
    },
    {
      label: "2024",
      value: 2024,
    },
  ],
};

const component = (props = {}) => {
  return myRentalsShallowMount(AccountingFilterYear, {
    props: { ...defaultProps, ...props },
  });
};

describe("AccountingFilterYear", () => {
  testMyRentalsShallowMount(AccountingFilterYear, {
    props: defaultProps,
  });

  describe("getters", () => {
    describe("defaultOption", () => {
      it("should be 0 if accountingYear NOT passed", () => {
        // Given
        const wrapper = component();
        // When
        // Then
        expect(wrapper.vm.defaultOption).toBe(0);
      });
      it("should be accountingYear if passed", () => {
        // Given
        const wrapper = component({
          ...defaultProps,
          accountingYear: 2024,
        });
        // When
        // Then
        expect(wrapper.vm.defaultOption).toBe(1);
      });
      it("should be accountingYear if passed BUT not in accountingYears", () => {
        // Given
        const wrapper = component({
          ...defaultProps,
          accountingYear: 2000,
        });
        // When
        // Then
        expect(wrapper.vm.defaultOption).toBe(0);
      });
    });
  });
});
