export const ErpJournalEntry = Object.freeze({
  8: "common.rentalsReservationBooked",
  9: "common.rentalsReservationPaid",
  10: "common.rentalsLeasingContractSigned",
  11: "common.rentalsInvoicePaid",
  12: "common.rentalsReservationCancelled",
  13: "common.rentalsReservationReturnedOwnersResponsability",
  14: "common.rentalsReservationCancelledTenantResponsabilityHalfForOwner",
  15: "common.rentalsReservationReturnedNobodysResponsability ",
  16: "common.rentalTenantChargeRemittanceGenerated",
  28: "common.rentalTenantChargeUnpaid",
  29: "common.rentalsRentalLeasingSingedFianza",
  30: "common.rentalsRentalLeasingSingedGarantia",
  31: "common.rentalsRentalLeasingSingedMesEnCurso",
  32: "common.rentalsRentalLeasingSingedMesPorAdelantado",
  36: "common.rentalExpenseOther",
  37: "common.rentalExpenseAdvanceOther",
  38: "common.rentalsDepositToOrganism",
  39: "common.rentalsDepositRecoveredFromOrganismIntoHousfy",
  40: "common.rentalsDepositInOrganismTrespassedToOwnerDueToChurn",
  41: "common.rentalsRentalLeasingInitialLiquidationTenant",
  42: "common.rentalsRentalLeasingInitialLiquidationOwner",
  43: "common.rentalSupplyManagement",
  47: "common.rentalTenantChargeRemittanceGeneratedReceiptv2",
  48: "common.rentalTenantChargeRemittanceReceiptReclassificationToOwnerProvider",
  49: "common.rentalTenantChargeRemittanceGrant",
  50: "common.rentalOwnerPaymentRemittanceInvoicedReclassificationFromOwnerToTransitoryAccount",
  51: "common.rentalOwnerPaymentRemittanceInvoicedReclassificationFromTransitoryAccountToProvider",
  52: "common.rentalOwnerPaymentRemittanceVatTaxExpense",
  53: "common.rentalsRentalLeasingSignedReclassificationFromTenantToTransitoryAccount",
  54: "common.rentalsRentalLeasingSignedReclassificationFromTransitoryAccountToOwnerProvider",
  55: "common.rentalsIrregularsReservationWithoutSupply",
  56: "common.rentalsIrregularsSupply",
  57: "common.rentalsIrregularsReservation",
  58: "common.rentalsManualErpJournalEntry",
  59: "common.rentalItpManagement",
  60: "common.rentalsTenantInvoiceableExpenses",
  61: "common.rentalsRentalLeasingFinalLiquidationTenant",
  62: "common.rentalsRentalLeasingfinalLiquidationOwner",
});
