import {
  testMyRentalsShallowMount,
  myRentalsShallowMount,
} from "@/tests/unit/setup";
import AccountingMonthlyCardsList from "@/components/AccountingMonthlyCardsList.vue";

const defaultProps = {
  accountingYear: 2028,
  accountingInAYear: {
    year: 2024,
    propertyUuid: "397985c0-768d-46d0-97de-5bdcd23e0ca1",
    ownerSummary: {
      userId: 718,
      percentageMinUnit: 10000,
      totalYearBalanceMinUnit: {
        amount: 212950,
        currency: "EUR",
      },
      months: [
        {
          factMonth: "2024-10-01",
          totalMonthBalanceMinUnit: {
            amount: 106475,
            currency: "EUR",
          },
          movements: [
            {
              concept: "E48",
              amountMinUnit: {
                amount: 113672,
                currency: "EUR",
              },
              date: "2024-10-01",
              downloadUrl: null,
              viewUrl: null,
            },
            {
              concept: "F36",
              amountMinUnit: {
                amount: -7197,
                currency: "EUR",
              },
              date: "2024-10-03",
              downloadUrl: null,
              viewUrl: null,
            },
          ],
        },
        {
          factMonth: "2024-11-01",
          totalMonthBalanceMinUnit: {
            amount: 106475,
            currency: "EUR",
          },
          movements: [
            {
              concept: "E48",
              amountMinUnit: {
                amount: 113672,
                currency: "EUR",
              },
              date: "2024-11-01",
              downloadUrl: null,
              viewUrl: null,
            },
            {
              concept: "F36",
              amountMinUnit: {
                amount: -7197,
                currency: "EUR",
              },
              date: "2024-11-05",
              downloadUrl: null,
              viewUrl: null,
            },
          ],
        },
      ],
    },
    totalPropertySummary: {
      totalYearBalanceMinUnit: {
        amount: 212950,
        currency: "EUR",
      },
      months: [
        {
          factMonth: "2024-10-01",
          totalMonthBalanceMinUnit: {
            amount: 106475,
            currency: "EUR",
          },
          movements: [
            {
              concept: "E48",
              amountMinUnit: {
                amount: 113672,
                currency: "EUR",
              },
              date: "2024-10-01",
              documentUrl: null,
              viewUrl: null,
            },
            {
              concept: "F36",
              amountMinUnit: {
                amount: -7197,
                currency: "EUR",
              },
              date: "2024-10-03",
              documentUrl: null,
              viewUrl: null,
            },
          ],
        },
        {
          factMonth: "2024-11-01",
          totalMonthBalanceMinUnit: {
            amount: 106475,
            currency: "EUR",
          },
          movements: [
            {
              concept: "E48",
              amountMinUnit: {
                amount: 113672,
                currency: "EUR",
              },
              date: "2024-11-01",
              downloadUrl: null,
              viewUrl: null,
            },
            {
              concept: "F36",
              amountMinUnit: {
                amount: -7197,
                currency: "EUR",
              },
              date: "2024-11-05",
              downloadUrl: null,
              viewUrl: null,
            },
          ],
        },
      ],
    },
  },
};

jest.mock("@/services/MyRentalsConfig");

const component = (props = {}) => {
  return myRentalsShallowMount(AccountingMonthlyCardsList, {
    props: { ...defaultProps, ...props },
  });
};

beforeEach(() => {
  jest.restoreAllMocks();
});

describe("AccountingMonthlyCardsList", () => {
  testMyRentalsShallowMount(AccountingMonthlyCardsList, {
    props: defaultProps,
  });

  describe("Methods", () => {
    describe("handleInvoicesButtonClick", () => {
      it("should return without setting a selectedItem if item.invoices passed by props is empty", () => {
        // given
        const mockedItem = {
          movements: [],
        };
        const wrapper = component();
        // when
        wrapper.vm.handleInvoicesButtonClick(mockedItem);
        // then
        expect(wrapper.vm.selectedItem).toBe(null);
      });

      it("should call method openFile with correct params and not set selectedItem if item.length is 1", () => {
        // given
        const mockedUrl = "www.test-url.es";
        const mockedItem = {
          movements: [{ documentUrl: mockedUrl, viewUrl: mockedUrl }],
        };
        const wrapper = component();
        spyOn(wrapper.vm, "openFile");
        // when
        wrapper.vm.handleInvoicesButtonClick(mockedItem);
        // then
        expect(wrapper.vm.openFile).toHaveBeenCalledWith(
          mockedItem.movements[0]
        );
        expect(wrapper.vm.selectedItem).toBe(null);
      });
      it("should not call method openFile but set selectedItem with item passed by params if item.length is > 1", () => {
        // given
        const mockedUrl = "www.test-url.es";
        const mockedItem = {
          movements: [
            { concept: "E36", downloadUrl: mockedUrl, viewUrl: mockedUrl }, // E36 = common.rentalExpenseOther
            { concept: "E36", downloadUrl: mockedUrl, viewUrl: mockedUrl }, // E36 = common.rentalExpenseOther
          ],
        };
        const wrapper = component();
        spyOn(wrapper.vm, "openFile");
        // when
        wrapper.vm.handleInvoicesButtonClick(mockedItem);
        // then
        expect(wrapper.vm.openFile).not.toHaveBeenCalled();
        expect(wrapper.vm.selectedItem).toEqual({
          ...mockedItem,
          invoices: [
            {
              links: [{ downloadUrl: mockedUrl, viewUrl: mockedUrl }],
              title: "common.rentalExpenseOther",
            },
            {
              links: [{ downloadUrl: mockedUrl, viewUrl: mockedUrl }],
              title: "common.rentalExpenseOther",
            },
          ],
        });
      });
    });
  });
});
