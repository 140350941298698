
import { Component, Prop, Vue } from "vue-property-decorator";
import { HousfyPageTemplate } from "housfy-ui-lib";

import {
  MAIN_LOGO,
  HEADER_OPTIONS,
  TAB_BAR_ITEMS,
  MENU_MOBILE,
} from "@/constants/HousfyPage";
import AuthService from "@/services/AuthService";
import {
  IHeaderOption,
  IHeaderOptionsTranslated,
  ITab,
  IMenuMobile,
  ISubgroupMenuMobile,
} from "@/models/HousfyPage";
import { IBackOptions } from "@/models/BackOptions";
import { IRightCustomButton } from "@/models/RightCustomButton";

@Component({
  components: { HousfyPageTemplate },
})
export default class HousfyPage extends Vue {
  @Prop({ type: String, default: null })
  title?: string;

  @Prop({ type: String, default: null })
  headerTitle?: string;

  @Prop({ type: Object, default: () => ({} as IBackOptions) })
  backOptions?: IBackOptions;

  @Prop({ type: String, default: null })
  activeTabId?: string;

  @Prop({ type: Boolean, default: false })
  isTopPage?: boolean;

  @Prop({ type: Boolean, default: true })
  customMainPadding?: boolean;

  @Prop({ type: Object, default: () => ({} as IRightCustomButton) })
  rightCustomButton?: IRightCustomButton;

  get headerOptions(): IHeaderOptionsTranslated {
    const horizontalOptions = HEADER_OPTIONS.horizontalOptions.map(
      (option) => ({
        ...option,
        label: `${this.$t(`${option.label}`).toString()}`,
      })
    );

    const menuOptions = this.$mqd
      ? HEADER_OPTIONS.menuOptionsDesktop
      : HEADER_OPTIONS.menuOptionsMobile;

    const menuOptionsTranslated = menuOptions.map((submenu) => {
      return {
        label: submenu.label ? this.$t(`${submenu.label}`) : undefined,
        subitems: submenu.subitems.map((option) => ({
          ...option,
          label: `${this.$t(`${option.label}`)}`,
        })),
      };
    });

    return {
      horizontalOptions,
      menuOptions: menuOptionsTranslated,
    };
  }
  get mainLogo(): IHeaderOption {
    return { ...MAIN_LOGO, label: `${this.$t(`${MAIN_LOGO.label}`)}` };
  }
  get isImpersonation(): boolean {
    return AuthService.isImpersonate();
  }
  get userAvatarUrl(): string {
    return AuthService.currentUser?.avatarUrl || "";
  }
  get userFirstName(): string {
    return AuthService.currentUser?.firstName || "";
  }
  get userLastName(): string {
    return AuthService.currentUser?.lastName || "";
  }

  get tabs(): ITab[][] {
    return TAB_BAR_ITEMS.map((sectionTabs): ITab[] =>
      Object.values(sectionTabs).map(
        (tab): ITab => ({
          ...tab,
          label: this.$t(tab.label),
        })
      )
    );
  }

  get menuMobile(): IMenuMobile {
    const title = `${this.$t(MENU_MOBILE.title)}`;
    const tabs: ISubgroupMenuMobile[] = MENU_MOBILE.tabs.map(
      (subitem: ISubgroupMenuMobile): ISubgroupMenuMobile => {
        return {
          label: subitem.label ? this.$t(`${subitem.label}`) : undefined,
          subitems: subitem.subitems.map((group: ITab): ITab => {
            return { ...group, label: this.$t(`${group.label}`) };
          }),
        };
      }
    );
    const cta = {
      ...MENU_MOBILE.cta,
      label: `${this.$t(`${MENU_MOBILE.cta.label}`)}`,
    };

    const footer = MENU_MOBILE.footer.map((item) => ({
      ...item,
      label: `${this.$t(`${item.label}`)}`,
    }));

    return {
      title,
      tabs,
      cta,
      footer,
    };
  }
}
