import {
  testMyRentalsShallowMount,
  myRentalsShallowMount,
} from "@/tests/unit/setup";
import InvoicesFlap from "@/components/InvoicesFlap.vue";
import { resolveRoutePath } from "@/router";

const defaultProps = {
  invoices: [
    {
      uuid: "111222-azxd1-asd",
      downloadUrl: "www.test.es",
      viewUrl: "www.test.es",
      url: "www.test.es",
      expiresAt: "string",
    },
  ],
};

const defaultMocks = {
  $router: { push: jest.fn() },
};

jest.mock("@/services/MyRentalsConfig");

const component = (props = {}, mocks = defaultMocks) => {
  return myRentalsShallowMount(InvoicesFlap, {
    props: { ...defaultProps, ...props },
    mocks: mocks,
    router: true,
  });
};

beforeEach(() => {
  jest.restoreAllMocks();
});

describe("InvoicesFlap", () => {
  testMyRentalsShallowMount(InvoicesFlap, {
    props: defaultProps,
  });

  describe("Methods", () => {
    describe("openFile", () => {
      it("should call window.open with the document-viewer route", () => {
        // given
        const mockedUrl = "www.test-url.es";
        const wrapper = component();
        spyOn(wrapper.vm.$router, "push");
        // when
        wrapper.vm.openFile(mockedUrl);
        // then
        const route = resolveRoutePath({
          path: "/document-viewer",
        });
        expect(wrapper.vm.$router.push).toHaveBeenCalledWith(route);
      });
    });
  });
});
