
import { Component, Vue } from "vue-property-decorator";
import HousfyPage from "@/components/protected/HousfyPage.vue";
import { HousfySpinner } from "housfy-ui-lib";
import Storage from "@/plugins/storage";
import { LocalStorageKeys } from "@/enums/Storage";
import { IBackOptions } from "@/models/BackOptions";
import { IRightCustomButton } from "@/models/RightCustomButton";

@Component({
  name: "DocumentsViewer",
  components: {
    HousfyPage,
    HousfySpinner,
  },
})
export default class DocumentsViewer extends Vue {
  loading = true;
  urlIframe: string | null = null;
  title: string | null = null;
  downloadUrl: string | null = null;

  get backOptions(): IBackOptions {
    return {
      enabled: true,
      text: this.$t("common.back"),
      minimal: this.$mqm,
      preventDefault: false,
      titleMobile: this.title ?? "",
    };
  }

  get downloadButton(): IRightCustomButton {
    return {
      enabled: true,
      text: this.$t("common.download"),
      minimal: this.$mqm,
      link: this.downloadUrl,
      preventDefault: false,
    };
  }

  async mounted() {
    if (
      Object.keys(Storage.getLocalStorageItem(LocalStorageKeys.IFRAME_URL))
        .length !== 0
    ) {
      const storageObject = Storage.getLocalStorageItem(
        LocalStorageKeys.IFRAME_URL
      );
      this.urlIframe = storageObject.links[0].viewUrl + "#toolbar=0";
      this.title = storageObject.title;
      this.downloadUrl = storageObject.links[0].downloadUrl;
      Storage.removeLocalStorageItem(LocalStorageKeys.IFRAME_URL);
      this.loading = false;
    } else {
      this.$router.back();
    }
  }

  redirectToUrl(url: string) {
    /* eslint-disable-next-line xss/no-location-href-assign */
    location.href = url;
  }
}
