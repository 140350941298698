
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  IAmount,
  IAccountingInAYear,
  IAccountingInAYearItem,
} from "@/models/IPropertyAccounting";
import { HousfyButton } from "housfy-ui-lib";
import IOption from "@/models/IOption";
import HousfySvg from "@/components/ui/HousfySvg.vue";
import Money from "@/models/Money";
import BarChartVertical from "@/components/charts/barChartVertical.vue";
import AccountingFilter from "@/components/AccountingFilter.vue";
import AccountingFilterYear from "@/components/AccountingFilterYear.vue";
import MyRentalsConfig from "@/services/MyRentalsConfig";
import { AccountingSummary } from "@/enums/Accounting";

type Filters = {
  year: IOption<number>;
  summary: IOption<number>;
};

@Component({
  components: {
    HousfySvg,
    BarChartVertical,
    AccountingFilter,
    AccountingFilterYear,
    HousfyButton,
  },
})
export default class AccountingMainSection extends Vue {
  isFilterVisible = false;
  accountingSummary = AccountingSummary;

  @Prop({ type: Number, required: true })
  accountingYear!: number;

  @Prop({ type: Object, required: true })
  accountingInAYear!: IAccountingInAYear;

  @Prop({ type: Array, required: true })
  accountingYears!: IOption<number>[];

  @Prop({ type: Number, required: true })
  ownerPercentage!: number;

  @Prop({ type: Number, default: AccountingSummary.TOTAL_PROPERTY })
  summary!: number;

  get tooltipContent(): string {
    if (this.summary === AccountingSummary.TOTAL_PROPERTY) {
      return `
        ${this.$t(
          "common.thisShowsTheIncomeAndExpensesOfTheTotalProperty"
        )}.<br />
        ${this.$t(
          "common.youCanFilterIfYouOnlyWantToSeeYourPartOfTheBalance"
        )}.`;
    }
    return `
        ${this.$t(
          "common.thisShowsTheIncomeAndExpensesForYourShareOfTheProperty"
        )}.<br />
        ${this.$t("common.youCanFilterIfYouWantToSeeTheTotalBalance")}.`;
  }

  get currentYearBalance(): IAmount {
    const { amount, currency } = this.summaryView.totalYearBalanceMinUnit;
    return {
      amount,
      currency: currency || "EUR",
    };
  }

  get summaryView() {
    if (this.summary === AccountingSummary.OWNER) {
      return this.accountingInAYear?.ownerSummary;
    }
    return this.accountingInAYear?.totalPropertySummary;
  }

  get accountingInAYearItemsSortedByMonthAsc(): IAccountingInAYearItem[] {
    const items = [...this.summaryView.months];
    return items?.sort((a, b) => {
      const dateA = new Date(a.factMonth);
      const dateB = new Date(b.factMonth);
      return dateA.getTime() - dateB.getTime();
    });
  }

  get chartConfig() {
    const data = [...this.accountingInAYearItemsSortedByMonthAsc];

    const months: string[] = [];
    const amounts: number[] = [];

    for (let i = 1; i <= 12; i++) {
      months.push(this.getMonthShortName(i).toUpperCase());
    }

    data.forEach((item) => {
      const month = new Date(item.factMonth).getMonth();
      amounts[month] = item.totalMonthBalanceMinUnit.amount;
    });

    const chartData = {
      labels: months,
      datasets: [
        {
          backgroundColor: ["#11AEFA"],
          data: amounts,
        },
      ],
    };
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      animationSteps: 100,
      animationEasing: "easeOutBounce",
      animation: {
        animateRotate: true,
      },

      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      datasets: {
        bar: {
          borderWidth: 0,
          borderRadius: 5,
        },
      },
      barThickness: 4,
      scales: {
        x: {
          offset: true,
          grid: {
            display: false,
            borderColor: "#F4F8F4",
            drawBorder: true,
            color: "#F4F8F4",
          },
          ticks: {
            color: "#A3BDBF",
          },
        },
        y: {
          offset: false,
          beginAtZero: true,
          grid: {
            drawBorder: false,
            color: "#F4F8F4",
          },
          ticks: {
            color: "#A3BDBF",
            interval: 1000,
            maxTicksLimit: 5,
            padding: 21,
            callback: function (value: number) {
              const number = new Money(value / 100)
                .withOptions({
                  withCents: false,
                })
                .format(true);

              return +number > 1000 ? +number / 1000 + "mil €" : number + " €";
            },
          },
        },
      },
    };

    return { chartData, chartOptions };
  }

  getMonthShortName(month: number): string {
    const date = new Date();
    date.setMonth(month - 1);
    const options = { month: "short" };

    return date.toLocaleString(MyRentalsConfig.country(), options);
  }

  formatNumber(
    number: number,
    withCurrency = false,
    currency?: string
  ): string {
    if (isNaN(number)) return "";
    return new Money(number / 100)
      .withOptions({
        withCents: true,
        currency,
      })
      .format(!withCurrency);
  }

  openFilter() {
    this.isFilterVisible = true;
  }

  closeFilter() {
    this.isFilterVisible = false;
  }

  handleFilterYear(year: IOption<number>) {
    this.$emit("filter", { year });
  }

  handleFilters(filters: Filters) {
    this.$emit("filter", filters);
    this.closeFilter();
  }
}
