
import { Component, Vue } from "vue-property-decorator";
import { IBackOptions } from "@/models/BackOptions";
import { IAddress } from "@/models/Address";
import { IRentalLeasings, IRentalLeasing } from "@/models/RentalLeasing";
import {
  IDocumentsInvoices,
  IDocumentFormatted,
  IDocumentFormattedLink,
  IDocumentInvoices,
} from "@/models/Documents";
import { IPropertyInfo } from "@/models/PropertyInfo";
import { TabbarIds } from "@/enums/HousfyPage";
import { ErpInvoiceType } from "@/constants/ErpInvoiceType";
import { resolveRoutePath } from "@/router";
import MYHExternalRoutes from "@/services/MYHExternalRoutes";
import HousfyPage from "@/components/protected/HousfyPage.vue";
import DocumentsPageContent from "@/components/protected/DocumentsPageContent.vue";
import PropertyPageLayout from "@/components/layout/PropertyPageLayout.vue";
import ContactPerson from "@/components/ContactPerson.vue";
import DateUtils from "@/services/utils/DateUtils";
import ErrorService from "@/services/ErrorService";
import api from "@/api";
import ContactService from "@/services/ContactService";
import LeasingsService from "@/services/LeasingsService";
import { HousfyInputSelect } from "housfy-ui-lib";

@Component({
  name: "DocumentsInvoices",
  components: {
    HousfyPage,
    DocumentsPageContent,
    PropertyPageLayout,
    ContactPerson,
    HousfyInputSelect,
  },
})
export default class DocumentsInvoices extends Vue {
  address: IAddress | null = null;
  propertyInfo: IPropertyInfo | null = null;
  leasings: IRentalLeasings | null = null;
  invoicesDocuments: IDocumentsInvoices | null = null;
  errorMessage: string | null = null;
  selectedYear: number | null = null;

  activeTabId = TabbarIds.ACTIVITY;
  loading = false;
  propertyUuid: string = this.$route.params.propertyUuid;

  async mounted() {
    this.loadInitialData();
  }

  async loadInitialData() {
    this.loading = true;

    try {
      [this.address, this.propertyInfo, this.leasings, this.invoicesDocuments] =
        await Promise.all([
          api.property().retrieveAddress(this.propertyUuid),
          api.property().retrieveInfo(this.propertyUuid),
          api.property().retrieveLeasings(this.propertyUuid),
          api.propertyDocuments().retrieveInvoices(this.propertyUuid),
        ]);

      const [mostRecentInvoiceDocument] = this.sortDocuments(
        this.invoicesDocuments.items
      );
      this.setSelectedYear({
        value: DateUtils.formatDate(mostRecentInvoiceDocument.date).year,
      });
    } catch (error) {
      this.errorMessage = ErrorService.getErrorFromCode(error);
    }

    this.loading = false;
  }
  get invoicesYears() {
    if (!this.invoicesDocuments?.items) return [];

    let years = this.invoicesDocuments?.items.map(
      (item) => DateUtils.formatDate(item.date).year
    );
    years = [...new Set(years)];

    return (
      years.map((year) => ({
        label: year.toString(),
        selectedLabel: year.toString(),
        value: year,
      })) || []
    );
  }
  get backOptions(): IBackOptions {
    return {
      enabled: true,
      text: this.$t("common.back"),
      minimal: this.$mqm,
      fallbackHref: resolveRoutePath(MYHExternalRoutes.myhHome()),
    };
  }
  get headerTitle() {
    return this.address?.fullAddress || "";
  }
  get currentLeasing(): IRentalLeasing | undefined {
    return LeasingsService.getCurrentLeasing(this.leasings?.items);
  }
  get showContactPerson(): boolean {
    return ContactService.shouldShowContactPerson(
      this.propertyInfo,
      this.currentLeasing
    );
  }
  get invoiceDocumentsFormatted(): IDocumentFormatted[] {
    if (!this.invoicesDocuments?.items) return [];

    const invoicesDocumentsFiltered = this.filterDocuments(
      this.invoicesDocuments?.items
    );
    const invoicesDocumentsSorted = this.sortDocuments(
      invoicesDocumentsFiltered
    );
    return invoicesDocumentsSorted.map((doc) => {
      let title;

      if (doc.type === 36) {
        title = `${this.$t(
          "common.rentalManagement"
        ).toString()} ${this.formatDateMonthYear(doc.date).toLowerCase()}`;
      } else if (this.$t(ErpInvoiceType[doc.type])) {
        title = this.$t(ErpInvoiceType[doc.type]).toString();
      } else {
        title = this.$t("common.documents").toString();
        const error = {
          message: `Missing lokalise key for document - DocumentType: ${doc.type}`,
        };
        ErrorService.handleGenericError(error);
      }
      const links = this.getDocumentLinks(doc);
      return {
        title,
        date:
          this.$t("common.uploadedBy") +
          " Housfy - " +
          this.formatDate(doc.date).toLowerCase(),
        isDownload: links?.length === 1,
        links,
        tracking: `link_document_${title
          .toLocaleLowerCase()
          .replace(/\s/g, "-")}`,
      };
    });
  }

  formatDate(stringDate: string): string {
    const { day, month, year } = DateUtils.formatDate(stringDate);
    return day + " " + this.$t("common." + month + "Short") + " " + year;
  }
  formatDateMonthYear(stringDate: string): string {
    const { month, year } = DateUtils.formatDate(stringDate);
    return this.$t("common." + month + "Short") + " " + year;
  }
  filterDocuments(documents: IDocumentInvoices[]): IDocumentInvoices[] {
    return documents.filter((document) => {
      const { year } = DateUtils.formatDate(document.date);
      return year === this.selectedYear;
    });
  }
  sortDocuments(documents: IDocumentInvoices[]): IDocumentInvoices[] {
    return documents.sort((a, b) => {
      const dateA = a?.date ? new Date(a?.date).getTime() : 0;
      const dateB = b?.date ? new Date(b?.date).getTime() : 0;
      return dateB - dateA;
    });
  }
  getDocumentLinks(document: IDocumentInvoices): IDocumentFormattedLink[] {
    if (!document.files) return [];
    return document.files.map(({ downloadUrl, viewUrl }) => ({
      downloadUrl,
      viewUrl,
    }));
  }
  setSelectedYear({ value }: { value: number }) {
    this.selectedYear = value;
  }
}
