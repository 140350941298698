/* eslint-disable no-unused-vars */
export enum enumMovementTypes {
  MONTHLY_INCOME = "monthlyIncome",
  REPERCUSSION = "repercussion",
}

export interface IAmount {
  amount: number;
  currency: string;
}

export interface IFile {
  uuid: string;
  downloadUrl: string;
  viewUrl: string;
  url: string;
  expiresAt: string;
}

export interface IDocumentViewer {
  links: IFile[];
  title: string;
}

export interface IAccountingInAYearItem {
  factMonth: string;
  movements: IMovement[];
  totalMonthBalanceMinUnit: IAmount;
}

export interface IAccountingInAYearItemDocumentViewer
  extends IAccountingInAYearItem {
  invoices?: IDocumentViewer[];
}

export interface IAccountingInAYearMovement {
  type: enumMovementTypes.MONTHLY_INCOME | enumMovementTypes.REPERCUSSION;
  concept: string;
  amount: IAmount;
  date: string;
}

export interface IAccountingYears {
  items: number[]; // list of years
}

export interface IAccountingInAYear {
  ownerSummary: IOwnerSummary;
  totalPropertySummary: IPropertySummary;
  year: number;
  propertyUuid: string;
}

export interface IPropertySummary {
  months: IAccountingInAYearItem[];
  totalYearBalanceMinUnit: IAmount;
}

export interface IOwnerSummary {
  months: IAccountingInAYearItem[];
  percentageMinUnit: number;
  totalYearBalanceMinUnit: IAmount;
  userId: number;
}

export interface IMovement {
  amountMinUnit: IAmount;
  concept: string;
  date: string;
  downloadUrl: string;
  viewUrl: string;
}
