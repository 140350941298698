import {
  testMyRentalsShallowMount,
  myRentalsShallowMount,
} from "@/tests/unit/setup";
import AccountingFilter from "@/components/AccountingFilter.vue";

const defaultProps = {
  accountingYears: [
    {
      label: "2025",
      value: 2025,
    },
    {
      label: "2024",
      value: 2024,
    },
  ],
  accountingYear: "2024",
};

const mockedYear = {
  label: "2025",
  value: 2025,
};

const mockedSummary = {
  label: "common.viewTheTotalProperty",
  value: 0,
};

const defaultFilterValues = [
  [
    {
      summary: mockedSummary,
      year: mockedYear,
    },
  ],
];

const component = (props = {}) => {
  return myRentalsShallowMount(AccountingFilter, {
    props: { ...defaultProps, ...props },
  });
};

describe("AccountingFilter", () => {
  testMyRentalsShallowMount(AccountingFilter, {
    props: defaultProps,
  });

  describe("methods", () => {
    describe("resetFilter", () => {
      it("should emit with default filter values", () => {
        // Given
        const wrapper = component();
        // When
        wrapper.vm.resetFilter();
        // Then
        expect(wrapper.emitted().reset).toStrictEqual(defaultFilterValues);
      });
    });
    describe("handleFilter", () => {
      it("should emit with proper filter values", () => {
        // Given
        const wrapper = component();
        // When
        wrapper.vm.selectYear(mockedYear);
        wrapper.vm.selectSummary(mockedSummary);
        wrapper.vm.handleFilter();
        // Then
        expect(wrapper.emitted().filter).toStrictEqual(defaultFilterValues);
      });
    });
  });
});
