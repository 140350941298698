
import { Component, Vue, Prop } from "vue-property-decorator";
import { IFile, IDocumentViewer } from "@/models/IPropertyAccounting";
import { HousfyFlapV2, DocumentsRow } from "housfy-ui-lib";
import Storage from "@/plugins/storage";
import { LocalStorageKeys } from "@/enums/Storage";
import { resolveRoutePath } from "@/router";

@Component({
  components: {
    HousfyFlapV2,
    DocumentsRow,
  },
})
export default class InvoicesFlap extends Vue {
  @Prop({ type: Array, default: () => [] })
  invoices!: IFile[];

  @Prop({ type: Boolean, default: true })
  viewUrl!: boolean;

  @Prop({ type: Boolean, default: false })
  onlyDownload!: boolean;

  openFile(url: string): void {
    if (!this.viewUrl) return;
    Storage.setLocalStorageItem(LocalStorageKeys.IFRAME_URL, url);
    const route = resolveRoutePath({
      name: "documentViewer",
    });
    this.$router.push(route);
  }

  openFileDownload(url: IDocumentViewer): void {
    window.open(url.links[0].downloadUrl);
  }
}
