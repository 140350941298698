
import { Component, Prop, Vue } from "vue-property-decorator";
import HousfySvg from "@/components/ui/HousfySvg.vue";
import { HousfyButton } from "housfy-ui-lib";
import { HousfyAgentType } from "@/constants/HousfyAgentType";
import { Emails } from "@/constants/Emails";

@Component({
  name: "ContactButton",
  components: { HousfySvg, HousfyButton },
})
export default class WhatsappButton extends Vue {
  @Prop({ type: String, default: Emails.RENTALS_SUPPORT })
  mail?: string;
  @Prop({ type: String, default: "" })
  whatsappNumber?: string;
  @Prop({ type: String, default: "" })
  agentType?: string;

  handleButtonClick() {
    if (this.shouldOpenWhatsappLink()) {
      /* eslint-disable-next-line xss/no-location-href-assign */
      window.open(`https://api.whatsapp.com/send?phone=${this.whatsappNumber}`);
    } else {
      /* eslint-disable-next-line xss/no-location-href-assign */
      location.href = `mailto:${this.mail}`;
    }
  }
  shouldOpenWhatsappLink() {
    if (!this.whatsappNumber) return false;
    return (
      this.agentType === HousfyAgentType.CAPTATOR ||
      this.agentType === HousfyAgentType.KAM
    );
  }
}
